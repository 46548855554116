<template>
  <slide-over-right :id="sorId" :full-width="true">
    <loading :active.sync="isLoading"></loading>
    <div class="flex flex-wrap">
      <div class="w-1/2 md-max:w-full">
        <div class="flex items-center pl-8 mt-6 md-max:pb-6">
          <div
            class="items-center justify-center cursor-pointer back-button"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <h2 class="ml-3 font-semibold text-gray-900 text-22px">
            {{ titleText }}
          </h2>
        </div>

        <div v-if="errors.length > 0" class="pl-6 text-red-500">
          Server error!
        </div>

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(confirmSubmit)" class="px-6">
            <div class="grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2">
              <text-input
                rules="required"
                label="Parking Name"
                v-model="form.name"
              />

              <ValidationProvider
                name="Assigned Service area"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  text="Assigned Service area *"
                />
                <t-rich-select
                  name="options"
                  :options="service_areas"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.geofence"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>

            <PlacesAutocomplete
              :label="`Search for a location`"
              :disabled="!!!form.geofence"
              :placeholder="
                !!form.geofence === false
                  ? 'Select a service area first'
                  : 'Enter address / location'
              "
              @update:place="onUpdatingPlace"
              @clear:place="() => (place = null)"
            />

            <div class="grid grid-cols-1 gap-2 mt-4 lg:grid-cols-2">
              <text-input
                label="Type"
                v-model="mapDrawingMode"
                :disabled="true"
              />

              <text-input
                rules="required"
                type="number"
                label="Maximum Vehicle"
                v-model="form.max_vehicle"
              />
            </div>

            <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
              <ValidationProvider name="Address" rules="" v-slot="{ errors }">
                <input-label :error="errors[0]" text="Address" />
                <t-textarea
                  rules="required"
                  v-model="form.address"
                ></t-textarea>
                <input-error-item :message="errors[0]" />
              </ValidationProvider>

              <ValidationProvider
                name="Parking Note"
                rules=""
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Parking Note" />
                <t-textarea
                  rules="required"
                  v-model="form.description"
                ></t-textarea>
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>

            <section class="-mt-4">
              <input-label
                class="my-2 font-bold"
                text="Vehicle outside boundary should"
              />

              <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
                <div
                  :class="
                    form.operator_visibility == true
                      ? 'toggle-button-active flex items-center justify-center'
                      : 'toggle-button flex items-center justify-center'
                  "
                >
                  <div class="grid w-3/5">
                    <span class="toggle-title">Visibility</span>
                    <span class="toggle-description">Visible for Operator</span>
                  </div>
                  <div class="flex justify-end w-2/5">
                    <div class="flex items-center">
                      <t-toggle
                        variant="success"
                        :checked="!!form.operator_visibility"
                        v-model="form.operator_visibility"
                      />
                      <span class="ml-2 text-sm font-semibold">{{
                        form.operator_visibility ? 'ON' : 'OFF'
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  :class="
                    form.end_ride_picture
                      ? 'toggle-button-active flex items-center justify-center'
                      : 'toggle-button flex items-center justify-center'
                  "
                >
                  <div class="grid w-3/5">
                    <span class="toggle-title">End ride picture</span>
                    <span class="toggle-description"
                      >Required Picture before Parking</span
                    >
                  </div>
                  <div class="flex justify-end w-2/5">
                    <div class="flex items-center">
                      <t-toggle
                        variant="success"
                        :checked="!!form.end_ride_picture"
                        v-model="form.end_ride_picture"
                      />
                      <span class="ml-2 text-sm font-semibold">{{
                        form.end_ride_picture ? 'ON' : 'OFF'
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <input-label
              class="my-2 mt-4 font-bold"
              text="Parking spot photo (Optional)"
              v-if="form.end_ride_picture"
            />

            <div
              class="flex items-center overflow-x-auto md-max:flex-wrap"
              v-if="form.end_ride_picture"
            >
              <div
                class="mr-4 width-160 height-160"
                v-for="(item, index) in show_images"
                :key="index"
              >
                <div class="w-full h-full" v-if="item.preImage">
                  <img
                    :src="item.image"
                    class="object-cover w-full h-full rounded"
                  />
                </div>
                <div class="w-full h-full" v-else>
                  <img :src="item" class="object-cover w-full h-full rounded" />
                </div>
              </div>

              <!-- todo: update the component -->
              <upload-image
                title="Add Photo"
                v-on:input="addNewImage"
                v-on:pre_input="addShowImage"
              />
            </div>

            <button type="submit" ref="submitButton" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>

        <div class="flex items-center justify-end pr-2 mt-6">
          <anchor-button variant="secondary" @click="goBack">
            Cancel
          </anchor-button>
          <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
        </div>
      </div>

      <div class="w-1/2 md-max:w-full md-max:pt-6">
        <gmap-map
          ref="map"
          :zoom="zoom"
          :center="center"
          map-type-id="terrain"
          style="width: 100%; height: 100vh;"
        >
          <gmap-polygon
            :paths="parseToOverlay(form.coords)"
            :editable="true"
            :options="drawingOptions"
            @paths_changed="updateEdited($event)"
          >
          </gmap-polygon>
        </gmap-map>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { ParkingAreaConfig } from '@/config/ParkingAreaConfig'
// import { ServiceAreaConfig } from '@/config/ServiceAreaConfig'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'AddEditParkingArea',
  components: {
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    AnchorButton: () => import('@/components/form/AnchorButton'),
    TextInput: () => import('@/components/form/TextInput'),
    InputLabel: () => import('@/components/form/InputLabel'),
    UploadImage: () => import('@/components/form/UploadImage'),
    PlacesAutocomplete: () => import('@/components/map/PlacesAutocomplete'),
  },
  data() {
    return {
      isLoading: false,
      title: 'Parking Area',
      service_areas: [],
      restricted_areas: [],
      parking_areas: [],
      slow_speed_areas: [],
      item: {},
      form: {
        name: null,
        geofence: null,
        max_vehicle: null,
        operator_visibility: false,
        end_ride_picture: false,
        coords: null,
        address: '',
        description: '',
      },
      center: {
        lat: 10,
        lng: 10,
      },
      errors: [],
      sorId: ParkingAreaConfig.events.sorId,
      scrollwheel: true,
      zoom: 12,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      markers: [{}],
      edited: null,
      map: null,
      drawingManager: null,
      mapDrawingMode: 'Polygonal',
      parsedPolygon: null,
      parsedServicePolygon: null,
      drawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00acff',
        clickable: true,
        draggable: true,
        editable: true, // TODO: make this work with wrapper func
        zIndex: 5,
      },
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      restrictedDrawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 2,
      },
      slowSpeedDrawingOptions: {
        fillColor: 'rgba(204, 204, 20, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#CCCC00',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 3,
      },
      parkingDrawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00acff',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 4,
      },
      service_bounds: null,
      images: [],
      show_images: [],
      // places autocomplete
      theZoom: null,
      location_name: '',
      current_coords: '',
    }
  },
  computed: {
    google: gmapApi,
    drawingType: function() {
      return [
        {
          id: 'CIRCLE',
          name: 'Circle',
        },
        {
          id: 'POLYGON',
          name: 'Polygon',
        },
      ]
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    titleText: function() {
      return this.isEditing ? `Edit ${this.title}` : `Add ${this.title}`
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    getServiceArea: function() {
      return this.form.geofence || undefined
    },
  },
  watch: {
    // parsedPolygon() {
    //   if (this.parsedPolygon) {
    //     this.bindPolygonClickListener(this.parsedPolygon)
    //   }
    // },
    getServiceArea(updatedID) {
      console.log('updateGeof = ', this.service_areas)
      this.service_areas.forEach((item) => {
        if (item.id == updatedID) {
          if (!this.$refs.map) return
          this.$refs.map.$mapPromise.then((map) => {
            this.map = map
            if (this.parsedPolygon) this.parsedPolygon.setMap(null)
            const overlay = this.parseToOverlay(item.coords)
            this.getServiceBounds(overlay)
            this.setServiceOverlayOnMap(overlay)
            this.setMapCenterToDrawingCenter(overlay, false)
            // save the current coords for places autocomplete
            this.current_coords = item.coords
            this.drawOtherZonesOnMap()
          })
        } else {
          this.removeOverlayMap()
        }
      })
    },
  },
  async created() {
    await this.loadServiceDropdown()
  },
  mounted() {
    EventBus.$on(ParkingAreaConfig.events.editingData, (item) => {
      this.show_images = []
      this.images = []

      if (item.id && item.id != undefined) {
        this.item = item
        this.form = {
          name: item.name,
          geofence: item.geofence.id,
          max_vehicle: item.max_vehicle,
          operator_visibility: item.operator_visibility,
          end_ride_picture: item.end_ride_picture,
          coords: item.coords,
          address: item.address,
          description: item.description,
        }
        this.parking_areas = this.parking_areas.filter(
          (area) => area.id !== item.id
        )
      } else {
        this.form = {}
        this.item = {}
        setTimeout(() => {
          if (!this.$refs.map) return
          this.$refs.map.$mapPromise.then((map) => {
            console.log('Hitting promise with', map)
            this.map = map
            this.initDrawingMode()
          })
        }, 400)
      }
      console.log('data = ', item, this.editingData)
    })
    this.$log.debug('EditData:', this.form)
    this.getCurrentLocation()

    console.log('Map Ref', this.$refs.map)
  },
  updated: function() {
    var self = this
    this.$nextTick(function() {
      console.log('updated')
      // Code that will run only after the
      // entire view has been re-rendered
      if (!self.$refs.map) return
      // the zoom is set only by places auto complete,
      // allow centeting & zooming the map
      //  if user is searching for a place
      if (this.theZoom) return

      this.$refs.map.$mapPromise.then((map) => {
        console.log('Hitting promise with', map)
        this.map = map
        if (this.isEditing) {
          console.warn({ isE: this.isEditing })
          //set service area view
          if (this.item.geofence.coords) {
            const overlay = this.parseToOverlay(this.item.geofence.coords)
            this.getServiceBounds(overlay)
            this.setServiceOverlayOnMap(overlay)
            this.drawOtherZonesOnMap()
          }
          //set parking draw
          const overlay = this.parseToOverlay(this.form.coords)
          // this.setOverlayOnMap(overlay)
          this.setMapCenterToDrawingCenter(overlay, false)
        }
        this.initDrawingMode()
      })
    })
  },
  methods: {
    updateEdited(mvcArray) {
      let sPaths = ''
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j)
          if (i === 0 && j === 0) {
            sPaths = sPaths.concat(`{${point.lat()},${point.lng()}}`)
          } else {
            sPaths = sPaths.concat(',', `{${point.lat()},${point.lng()}}`)
          }
        }
      }
      this.form.coords = sPaths
    },

    goBack() {
      this.theZoom = null
      dispatchEvent(new Event(ParkingAreaConfig.events.sorToggle))
    },
    initDrawingMode() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null)
      }
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            this.google.maps.drawing.OverlayType.POLYGON,
            this.google.maps.drawing.OverlayType.CIRCLE,
          ],
        },
        // markerOptions: {
        //   icon:
        //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
        // },
        circleOptions: this.drawingOptions,
        polygonOptions: this.drawingOptions,
      })
      console.log('Manager ', this.drawingManager)
      this.drawingManager.setMap(this.map)
      this.google.maps.event.addListener(
        this.drawingManager,
        'overlaycomplete',
        this.overlayCompleted
      )
      // this.google.maps.event.addListener(
      //   this.drawingManager,
      //   "polygoncomplete",
      //   function(polygon) {
      //   }
      // );
      this.google.maps.event.addListener(
        this.drawingManager,
        'circlecomplete',
        this.circleCompleted
      )
    },
    getServiceBounds(overlay) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of overlay) {
        bounds.extend(path)
      }
      this.service_bounds = bounds
    },
    setServiceOverlayOnMap(payload) {
      this.parsedServicePolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.serviceDrawingOptions,
      })
      this.parsedServicePolygon.setMap(this.map)
      console.log('po = ', this.parsedServicePolygon)
    },
    removeOverlayMap() {
      if (this.parsedServicePolygon) this.parsedServicePolygon.setMap(null)
    },
    checkContains(event) {
      var exist_flag = true

      var bounds = new this.google.maps.LatLngBounds()
      for (const path of event) {
        bounds.extend(path)
      }

      this.form.center_coords =
        bounds.getCenter().lat() + ',' + bounds.getCenter().lng()

      for (const path of event) {
        if (!this.service_bounds.contains(path)) {
          exist_flag = false
          break
        }
      }
      console.log(exist_flag)
      return exist_flag
    },
    circleCompleted(event) {
      if (event.type == this.google.maps.drawing.OverlayType.Circle) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        this.mapDrawingMode = 'Circular'
        this.form.draw_type = 'C'
        const coordStr = this.getCircleCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    overlayCompleted(event) {
      if (event.type == this.google.maps.drawing.OverlayType.POLYGON) {
        if (this.parsedPolygon) this.parsedPolygon.setMap(null)
        // this.bindPolygonClickListener(event.overlay);
        this.mapDrawingMode = 'Polygonal'
        this.form.draw_type = 'P'
        const coordStr = this.getPolygonCoords(event)
        this.setDrawnCoords(coordStr, event)
      }
    },
    bindPolygonClickListener(overlay) {
      console.log(overlay)
    },
    getCircleCoords(circle) {
      var numPts = 20
      var path = []
      for (var k = 0; k < numPts; k++) {
        path.push(
          this.google.maps.geometry.spherical.computeOffset(
            circle.getCenter(),
            circle.getRadius(),
            (k * 360) / numPts
          )
        )
      }
      console.log('Circle Path', path)
      var dumpStr = ''
      for (var i = 0; i < path.length; i++) {
        console.log(path[i].lat())
        dumpStr += `{${path[i].lat()},${path[i].lng()}},`
      }
      console.log('Circle draw completed', dumpStr)
      return dumpStr
    },
    getCoordStrFromCoordArray(coordinatesArray, isNativePolygon = true) {
      var dumpStr = ''
      for (var item of coordinatesArray) {
        console.log(item)
        dumpStr += `{${isNativePolygon ? item.lat() : item.lat},${
          isNativePolygon ? item.lng() : item.lng
        }},`
      }
      console.log('Poly draw completed', dumpStr)
      return dumpStr
    },
    getPolygonCoords(polygon) {
      var coordinatesArray = polygon.overlay.getPath().getArray()
      return this.getCoordStrFromCoordArray(coordinatesArray, true)
    },
    setDrawnCoords(coords, sender) {
      // this is a tricky code--added for re-rendering
      if (this.title === 'Parking Area ') {
        this.title = 'Parking Area'
      } else {
        this.title = 'Parking Area '
      }
      this.form.coords = coords
      console.log(sender)
      if (this.parsedPolygon) {
        this.parsedPolygon.setMap(null)
      }
      // this.drawingManager.setMap(null);
      if (this.parsedPolygon !== sender) {
        sender.overlay ? sender.overlay.setMap(null) : sender.setMap(null)
      }
      // this.initDrawingMode();
      var that = this
      this.$nextTick(function() {
        const overlay = that.parseToOverlay(coords)
        if (this.service_bounds != null && this.checkContains(overlay)) {
          // that.setOverlayOnMap(overlay)

          that.setMapCenterToDrawingCenter(overlay, true)
        } else {
          // alert('Please draw Parking Area inside Service Area.')
        }
      })
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!Array.isArray(coordsArr) || !coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    setOverlayOnMap(payload) {
      this.parsedPolygon = new this.google.maps.Polygon({
        paths: payload,
        ...this.drawingOptions,
      })

      this.parsedPolygon.setMap(this.map)
      console.log(payload)
      console.log('SET ON MAP')
    },
    // updatePolygonBounds(index, path, payload) {
    //   console.log('HIT UPDATE POLY...', payload[index])
    //   if (!this.parsedPolygon) return
    //   payload[index] = {
    //     lat: parseFloat(path.lat()),
    //     lng: parseFloat(path.lng()),
    //   }
    //   console.log('HIT UPDATED POLY...', {
    //     lat: parseFloat(path.lat()),
    //     lng: parseFloat(path.lng()),
    //   })
    //   // payload[index] = path
    //   const coordStr = this.getCoordStrFromCoordArray(payload, false)
    //   console.log('STORED COORDS', this.form.coords)
    //   console.log('DIFFED COORDS', coordStr)
    //   this.form.coords = coordStr
    //   console.log('STORED UPD', this.form.coords)
    //   // this.setDrawnCoords(coordStr, this.parsedPolygon)
    // },
    setMapCenterToDrawingCenter(
      paths,
      panToCenter = true,
      latlng = null,
      zoom = null
    ) {
      var bounds = new this.google.maps.LatLngBounds()
      for (const path of paths) {
        bounds.extend(path)
      }
      const newCenter = bounds.getCenter()
      console.log('NEW CENTER ', newCenter, panToCenter, latlng, zoom)

      if (panToCenter) {
        this.map.panTo(newCenter)
      }

      if (latlng) {
        this.center.lat = latlng.lat
        this.center.lng = latlng.lng
      } else {
        this.center.lat = newCenter.lat()
        this.center.lng = newCenter.lng()
      }

      if (zoom) {
        this.zoom = zoom
      } else {
        //reset map zoom based on new poly (can't see whole poly on render if the poly is bigger then the current set zoom)
        this.map.fitBounds(bounds)
      }
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position);
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude
      })
    },
    checkValidPolygon(coordStr) {
      if (!coordStr) return false

      const coords = coordStr.split(',')
      return Array.isArray(coords) && coords.length > 2
    },
    submit: async function() {
      console.log('COORDS', this.form.coords)
      if (!this.checkValidPolygon(this.form.coords)) {
        alert(
          'Please draw a valid polygon containing more than two coordinates'
        )
        return
      }
      if (typeof this.form.operator_visibility === 'undefined') {
        this.form.operator_visibility = false
      }
      if (typeof this.form.end_ride_picture === 'undefined') {
        this.form.end_ride_picture = false
      }
      console.log('form===', this.form)
      if (this.form.coords === undefined || this.form.coords === null) {
        this.isLoading = false
        alert('Please draw a valid Parking Area (Polygon/Circle)')
        return
      } else {
        this.isLoading = false
      }
      this.$refs.submitButton.click()
    },
    confirmSubmit: async function() {
      this.isLoading = true
      console.log('form = ', this.images)
      if (this.form.description == undefined) {
        this.form.description = ''
      }
      if (this.form.address == undefined) {
        this.form.address = ''
      }

      delete this.form.draw_type

      if (this.form.max_vehicle)
        this.form.max_vehicle = Number(this.form.max_vehicle)

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? ParkingAreaConfig.api.update(this.item.id)
        : ParkingAreaConfig.api.create

      let data = new xMan().toFormData()

      data.append('coords', this.form.coords)
      data.append('geofence', this.form.geofence)
      data.append('name', this.form.name)
      data.append('address', this.form.address)
      data.append('max_vehicle', this.form.max_vehicle)
      data.append('description', this.form.description)

      data.append('operator_visibility', this.form.operator_visibility)
      data.append('end_ride_picture', this.form.end_ride_picture)
      data.append('center_coords', this.form.center_coords)
      this.images.forEach((item) => {
        data.append('parking_images', item)
      })

      let message = this.isEditing
        ? 'Parking Area updated successfully'
        : 'Parking Area added successfully'

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(ParkingAreaConfig.events.sorToggle))
        dispatchEvent(new Event(ParkingAreaConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        var errorData = Object.values(error.response.data)
        console.log(errorData)
        // window.alert(errorData[0])
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Error',
            text: errorData[0],
          },
          3000
        )
        this.isLoading = false
      }
    },
    async onUpdatingPlace(data) {
      var lat = data.geometry.location.lat().toString()
      var lng = data.geometry.location.lng().toString()

      console.log('lat = ', lat, lng)

      const center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }

      const overlay = this.parseToOverlay(this.current_coords)
      console.warn(overlay)
      this.getServiceBounds(overlay)
      this.setServiceOverlayOnMap(overlay)
      // varying zoom
      this.theZoom = 18
      if (this.zoom === this.theZoom) {
        this.theZoom = 17
      } else {
        this.theZoom = 18
      }
      this.setMapCenterToDrawingCenter(overlay, false, center, this.theZoom)
      // this.initDrawingMode()

      // this.zoom = 50
    },
    addNewImage(file) {
      console.log('file = ', file)
      this.images.push(file)
    },
    addShowImage(pre_file) {
      console.log('file = ', pre_file)
      var obj = {
        preImage: true,
        image: pre_file,
      }
      this.show_images.push(obj)
    },
    async loadServiceDropdown() {
      // console.log('service = ', this.$config.dorpdown.api.serviceAreas)
      const areasDataAPICalls = [
        this.$http.get(`${this.$config.serviceArea.api.index}?export=true`),
        this.$http.get(`${this.$config.slowSpeedArea.api.index}?export=true`),
        this.$http.get(`${this.$config.restrictedArea.api.index}?export=true`),
        this.$http.get(`${this.$config.parkingArea.api.index}?export=true`),
      ]
      try {
        const responses = await Promise.all(areasDataAPICalls)
        this.service_areas = responses[0].data.data
        this.slow_speed_areas = responses[1].data.data
        this.restricted_areas = responses[2].data.data
        this.parking_areas = responses[3].data.data
      } catch (err) {
        console.log('Error loading areas data', err)
      }
    },
    drawOtherZonesOnMap() {
      const otherAreaBounds = []
      const otherParsedPolygons = []
      this.restricted_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.restrictedDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.slow_speed_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.slowSpeedDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.parking_areas.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.parkingDrawingOptions,
        })
        otherAreaBounds.push(bounds)
        parsedPolygon.setMap(this.map)
        otherParsedPolygons.push(parsedPolygon)
      })

      this.otherAreaBounds = otherAreaBounds
      this.otherParsedPolygons = otherParsedPolygons
    },
  },
}
</script>

<style lang="scss">
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding: 4px 0; // 15px 0?

  & .left {
    width: 65%;
    padding-right: 0.5rem;
    font-size: 0.875rem;
  }

  & .right {
    width: 35%;
    padding-left: 0.5rem;
  }
}
.toggle-button-active {
  border: 1px solid #1bca09;
  width: 100%;
  height: 140px;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-button {
  width: 100%;
  height: 140px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.toggle-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.toggle-description {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  @apply text-gray-600;
}
.places-autocomplete {
  @apply relative block w-full px-4 py-2 leading-normal border border-gray-400 rounded outline-none h-36px;

  &:focus {
    @apply outline-none border-gray-700;
  }
  &.enabled {
    @apply bg-white;
  }
  &.disabled {
    @apply bg-oLightestGray;
  }
}
.vbga-results {
  @apply absolute w-full bg-white border rounded-b-lg text-14px font-medium text-oDark;
  top: 60px;
  left: 0;
  z-index: 5;

  li {
    padding: 0.65rem;
    cursor: pointer;
    @apply truncate flex justify-between items-center cursor-pointer;

    &.highlighted {
      @apply bg-gray-300 font-semibold;
    }
  }
}
</style>
